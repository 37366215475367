import { Collapse, CollapseProps, Space } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { PiiApi } from 'src/api'
import { TUserPii } from 'src/graphql'
import { useUnsubscribe } from 'src/hooks'
import { EPaths } from 'src/modules/market/routes.path'
import { DialogEditKycAddress, DialogEditKycIdentity, DialogKycComment, DialogKycDocument } from 'src/partials'
import { DialogKycActions } from 'src/partials/dialog-kyc-actions'
import { ApplicationProgress, Changelog, Document, QuestionsAnswered, UserAddress, UserComplyCubeChecks, UserIdentity } from 'src/partials/individual-kyc'
import { BreadcrumbService, MessageService } from 'src/services'
import { EventUtils, KycUtils } from 'src/utils'

interface IProps {
  accountType?: 'business' | 'individual'
}

export const IndividualKycDetail: FC<IProps> = ({ accountType = 'individual' }) => {
  const unsubscribe$ = useUnsubscribe()
  const { accountID, userID } = useParams<{
    accountID: string
    userID: string
  }>()
  const [user, setUser] = useState<TUserPii>()
  const [loading, setLoading] = useState(false)

  /**
   * breadcrumb
   */
  useEffect(() => {
    // success
    // processing
    // error
    // default
    // warning
    const kycReviewState = user?.kycReviewState
    const route = accountType === 'business'
      ? EPaths.MARKET_BUSINESS_KYC
      : EPaths.MARKET_INDIVIDUAL_KYC
    const subRoute = accountType === 'business'
      ? EPaths.MARKET_BUSINESS_KYC_DETAIL
      : EPaths.MARKET_INDIVIDUAL_KYC_DETAIL
    const label = accountType === 'business'
      ? 'Business KYC'
      : 'Individual KYC'
    BreadcrumbService.items = [
      {
        route,
        label
      },
      {
        route: [subRoute, { accountID, userID }],
        label: user?.id
          ? (
            <Space>
              {user?.email}
              {!!kycReviewState && KycUtils.renderKycStatusBadge(kycReviewState)}
            </Space>
          )
          : accountID
      }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [accountID, user?.email, user?.id, user?.kycReviewState, userID, accountType])

  const pullUserDetails = useCallback(() => {
    if (!userID || !accountID) {
      return
    }

    setLoading(true)
    from(PiiApi.getUserIdentity({
      input: {
        accountID,
        userID
      }
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          console.log('error', error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe((userIdentity) => {
        setUser(userIdentity as TUserPii)
      })
  }, [unsubscribe$, userID, accountID])

  const onUpdated = useCallback(() => {
    pullUserDetails()
  }, [pullUserDetails])

  const onRecalculateRiskScore = useCallback(() => {
    if (!user?.kycAttemptID) {
      MessageService.error('Something went wrong!')
      return
    }

    setLoading(true)
    from(PiiApi.recalculateRiskScore({ attemptID: user.kycAttemptID }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          console.log('error', error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        pullUserDetails()
      })
  }, [unsubscribe$, user?.kycAttemptID, pullUserDetails])

  useEffect(() => {
    pullUserDetails()
  }, [pullUserDetails])

  const items: CollapseProps['items'] = useMemo(() => [
    {
      key: '1',
      label: 'Application progress',
      children: (
        <ApplicationProgress
          user={user}
          onUpdated={onUpdated}
          onRecalculateRiskScore={onRecalculateRiskScore}
          loading={loading}
        />
      )
    },
    {
      key: '2',
      label: 'User Identity',
      children: <UserIdentity user={user}/>,
      extra: (
        <div onClick={e => EventUtils.stopPropagation(e)}>
          {user && (
            <DialogEditKycIdentity
              user={user}
              btnProps={{
                type: 'link',
                style: { margin: '-8px 0' }
              }}
              afterClose={(updated) => updated && onUpdated()}
            />
          )}
        </div>
      )
    },
    {
      key: '3',
      label: 'Comply Cube checks',
      children: <UserComplyCubeChecks user={user}/>
    },
    {
      key: '4',
      label: 'User Address',
      children: <UserAddress user={user}/>,
      extra: (
        <div onClick={e => EventUtils.stopPropagation(e)}>
          {user?.currentKyc?.address && (
            <DialogEditKycAddress
              user={user}
              btnProps={{
                type: 'link',
                style: { margin: '-8px 0' }
              }}
              afterClose={(updated) => updated && onUpdated()}
            />
          )}
        </div>
      )
    },
    {
      key: '5',
      label: 'Document',
      children: <Document user={user}/>,
      extra: (
        <div onClick={e => EventUtils.stopPropagation(e)}>
          {user?.currentKyc && (
            <DialogKycDocument
              user={user}
              btnProps={{
                type: 'link',
                style: { margin: '-8px 0' }
              }}
              afterClose={(updated) => updated && onUpdated()}
            />
          )}
        </div>
      )
    },
    {
      key: '6',
      label: 'Questions answered',
      children: <QuestionsAnswered user={user}/>
    },
    {
      key: '7',
      label: 'Change log',
      children: <Changelog user={user}/>,
      extra: (
        <div onClick={e => EventUtils.stopPropagation(e)}>
          {user && (
            <DialogKycComment
              user={user}
              btnProps={{
                type: 'link',
                style: { margin: '-8px 0' }
              }}
              afterClose={(updated) => updated && onUpdated()}
            />
          )}
        </div>
      )
    }
  ], [user, loading, onUpdated, onRecalculateRiskScore])

  return (
    <section className="fx fx-column fx-extend gap-3">
      {user && (
        <Space className="fx-jc-flex-end">
          {KycUtils.canOnHold(user) && (
            <DialogKycActions
              user={user}
              action="on_hold"
              title="On Hold"
              btnProps={{ type: 'primary' }}
              afterClose={(updated) => updated && onUpdated()}
            >
              Onhold
            </DialogKycActions>
          )}
          {KycUtils.canStartReview(user) && (
            <DialogKycActions
              user={user}
              action="start_review"
              title="Start Review"
              btnProps={{ type: 'primary' }}
              afterClose={(updated) => updated && onUpdated()}
            >
              Start Review
            </DialogKycActions>
          )}
          {KycUtils.canApprove(user) && (
            <DialogKycActions
              user={user}
              action="approve"
              title="Approve"
              btnProps={{ type: 'primary' }}
              afterClose={(updated) => updated && onUpdated()}
            >
              Approve
            </DialogKycActions>
          )}
          {KycUtils.canDeny(user) && (
            <DialogKycActions
              user={user}
              action="deny"
              title="Deny"
              btnProps={{ type: 'primary', danger: true }}
              afterClose={(updated) => updated && onUpdated()}
            >
              Deny
            </DialogKycActions>
          )}
        </Space>
      )}

      <Collapse
        items={items}
        defaultActiveKey={['1']}
      />
    </section>
  )
}
