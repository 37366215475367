/**
 * @generated SignedSource<<2c338c779190bb283d1cef37f63c9db5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BusinessAccountIdentityBusinessType = "CORP" | "INC" | "LLC" | "LLP" | "LP" | "LTD" | "OTHER" | "PLC" | "SP" | "%future added value";
export type BusinessAccountIdentityFinalState = "DONE" | "NOT_STARTED" | "ONGOING" | "ON_HOLD" | "REJECTED" | "REVIEW_IN_PROGRESS" | "%future added value";
export type ComplyCubeCheckCcOutcome = "attention" | "clear" | "not_processed" | "rejected" | "%future added value";
export type ComplyCubeCheckCcStatus = "complete" | "failed" | "pending" | "%future added value";
export type ComplyCubeCheckCcType = "document_check" | "extensive_screening_check" | "identity_check" | "proof_of_address_check" | "standard_screening_check" | "%future added value";
export type KYBActionLogAction = "approve" | "comment" | "edit_profile" | "escalate" | "in_progress" | "info_request" | "on_hold" | "reject" | "start_kyb" | "submit_profile" | "upload_documents" | "%future added value";
export type KYBActionLogCurrentAttemptState = "DONE" | "NOT_STARTED" | "ONGOING" | "ON_HOLD" | "REJECTED" | "REVIEW_IN_PROGRESS" | "%future added value";
export type KYBActionLogPrevAttemptState = "DONE" | "NOT_STARTED" | "ONGOING" | "ON_HOLD" | "REJECTED" | "REVIEW_IN_PROGRESS" | "%future added value";
export type KYBActionLogSubjectType = "admin" | "system" | "user" | "%future added value";
export type KYBAttemptState = "DONE" | "NOT_STARTED" | "ONGOING" | "ON_HOLD" | "REJECTED" | "REVIEW_IN_PROGRESS" | "%future added value";
export type KYBDocumentType = "BENEFICIAL_OWNER_DECLARATION" | "BOARD_RESOLUTION" | "BUSINESS_DETAILS" | "CERT_INCORPORATION" | "CERT_INCUMBENCY" | "CORPORATE_STRUCTURE_OWNERSHIP" | "MEMORANDUM_ARTICLES" | "OTHER" | "PROOF_SOURCE_FUNDS" | "REGISTER_OF_DIRECTORS" | "REGISTER_OF_SHAREHOLDERS" | "STAKEHOLDER_DETAILS" | "%future added value";
export type KYBDocumentUploaderRole = "admin" | "user" | "%future added value";
export type KYBReviewAction = "approve" | "comment" | "escalate" | "in_progress" | "info_request" | "on_hold" | "reject" | "%future added value";
export type KYCActionLogAction = "answer_kyc_question" | "approve" | "create_identity_check" | "create_personal_address" | "create_personal_address_check" | "deny_blacklist" | "deny_close" | "deny_reject" | "edit_address" | "edit_info" | "edit_risk_score" | "edit_risk_tag" | "re_calculate_risk_score" | "receive_aml_result" | "receive_document_result" | "receive_identity_result" | "receive_poa_result" | "review" | "start_kyc" | "update_personal_info" | "upload_document" | "%future added value";
export type KYCActionLogCurrentAttemptState = "ADDRESS_PROVIDED" | "ADDRESS_REQUIRED" | "AML_CHECKED" | "ANSWER_REQUIRED" | "DOCUMENT_EDITED" | "DONE" | "FAILED" | "IDENTITY_CHECKED" | "IDENTITY_DOCUMENT_SUBMITTED" | "KYC_QUESTION_ANSWERED" | "MANUAL_APPROVE_PENDING" | "NO_KYC" | "POA_REQUIRED" | "POA_SUBMITTED" | "POA_VERIFIED" | "%future added value";
export type KYCActionLogCurrentReviewState = "APPLICATION_NOT_STARTED" | "APPLICATION_ONGOING" | "ESCALATED" | "FAILURE_AUTO_TERMINAL_REPEATED" | "INFO_REQUESTED" | "IN_PROGRESS" | "ON_HOLD" | "PENDING" | "RESTART_CLOSED" | "RESTART_IDENTITY_CHECK_FAILED" | "RESTART_REJECTED" | "SUCCESS_AUTO" | "SUCCESS_MANUAL" | "TERMINAL_BLACKLISTED" | "TERMINAL_IDENTITY_BLACKLISTED" | "%future added value";
export type KYCActionLogPrevAttemptState = "ADDRESS_PROVIDED" | "ADDRESS_REQUIRED" | "AML_CHECKED" | "ANSWER_REQUIRED" | "DOCUMENT_EDITED" | "DONE" | "FAILED" | "IDENTITY_CHECKED" | "IDENTITY_DOCUMENT_SUBMITTED" | "KYC_QUESTION_ANSWERED" | "MANUAL_APPROVE_PENDING" | "NO_KYC" | "POA_REQUIRED" | "POA_SUBMITTED" | "POA_VERIFIED" | "%future added value";
export type KYCActionLogPrevReviewState = "APPLICATION_NOT_STARTED" | "APPLICATION_ONGOING" | "ESCALATED" | "FAILURE_AUTO_TERMINAL_REPEATED" | "INFO_REQUESTED" | "IN_PROGRESS" | "ON_HOLD" | "PENDING" | "RESTART_CLOSED" | "RESTART_IDENTITY_CHECK_FAILED" | "RESTART_REJECTED" | "SUCCESS_AUTO" | "SUCCESS_MANUAL" | "TERMINAL_BLACKLISTED" | "TERMINAL_IDENTITY_BLACKLISTED" | "%future added value";
export type KYCActionLogSubjectType = "admin" | "system" | "user" | "%future added value";
export type KYCAttemptState = "ADDRESS_PROVIDED" | "ADDRESS_REQUIRED" | "AML_CHECKED" | "ANSWER_REQUIRED" | "DOCUMENT_EDITED" | "DONE" | "FAILED" | "IDENTITY_CHECKED" | "IDENTITY_DOCUMENT_SUBMITTED" | "KYC_QUESTION_ANSWERED" | "MANUAL_APPROVE_PENDING" | "NO_KYC" | "POA_REQUIRED" | "POA_SUBMITTED" | "POA_VERIFIED" | "%future added value";
export type KYCAttemptUserType = "business" | "individual" | "%future added value";
export type KYCReviewAction = "approve" | "deny_black_list" | "deny_close" | "deny_reject" | "escalate" | "in_progress" | "on_hold" | "request_info" | "%future added value";
export type KYCReviewDocumentType = "driving_license" | "national_identity_card" | "other" | "passport" | "proof_of_address" | "proof_source_of_fund" | "%future added value";
export type KYCRiskScoreLevel = "high" | "low" | "medium" | "%future added value";
export type KYCRiskScoreVersion = "v1" | "v2" | "%future added value";
export type KYCTagValue = "AML_HIT" | "DOCUMENT_ATTENTION" | "DOCUMENT_CHECK_NOT_PROCESSED" | "HIGH_RISK" | "IDENTITY_CHECK_NOT_PROCESSED" | "ID_ATTENTION" | "ID_EDITED" | "ID_MISMATCH" | "IP_COUNTRY_MISMATCH" | "ISSUING_COUNTRY_MISSING" | "LOW_RISK" | "MEDIUM_RISK" | "POA_ATTENTION" | "POA_EXPIRED" | "POA_MISMATCH" | "PROHIBITED_COUNTRY" | "UNSUPPORTED_DOCUMENT_TYPE" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type QuestionQuestionSet = "kyc_v1" | "%future added value";
export type QuestionType = "multiple_choice" | "single_choice" | "text" | "%future added value";
export type UserIdentityKycReviewState = "APPLICATION_NOT_STARTED" | "APPLICATION_ONGOING" | "ESCALATED" | "FAILURE_AUTO_TERMINAL_REPEATED" | "INFO_REQUESTED" | "IN_PROGRESS" | "ON_HOLD" | "PENDING" | "RESTART_CLOSED" | "RESTART_IDENTITY_CHECK_FAILED" | "RESTART_REJECTED" | "SUCCESS_AUTO" | "SUCCESS_MANUAL" | "TERMINAL_BLACKLISTED" | "TERMINAL_IDENTITY_BLACKLISTED" | "%future added value";
export type UserIdentityOrderField = "CREATE_TIME" | "KYC_REVIEW_STATE" | "NATIONALITY" | "UPDATE_TIME" | "%future added value";
export type UserIdentityType = "business" | "individual" | "%future added value";
export type UserIdentityWhereInput = {
  accountID?: string | null;
  accountIDContains?: string | null;
  accountIDContainsFold?: string | null;
  accountIDEqualFold?: string | null;
  accountIDGT?: string | null;
  accountIDGTE?: string | null;
  accountIDHasPrefix?: string | null;
  accountIDHasSuffix?: string | null;
  accountIDIn?: ReadonlyArray<string> | null;
  accountIDLT?: string | null;
  accountIDLTE?: string | null;
  accountIDNEQ?: string | null;
  accountIDNotIn?: ReadonlyArray<string> | null;
  and?: ReadonlyArray<UserIdentityWhereInput> | null;
  businessAccountIdentityID?: string | null;
  businessAccountIdentityIDContains?: string | null;
  businessAccountIdentityIDContainsFold?: string | null;
  businessAccountIdentityIDEqualFold?: string | null;
  businessAccountIdentityIDGT?: string | null;
  businessAccountIdentityIDGTE?: string | null;
  businessAccountIdentityIDHasPrefix?: string | null;
  businessAccountIdentityIDHasSuffix?: string | null;
  businessAccountIdentityIDIn?: ReadonlyArray<string> | null;
  businessAccountIdentityIDIsNil?: boolean | null;
  businessAccountIdentityIDLT?: string | null;
  businessAccountIdentityIDLTE?: string | null;
  businessAccountIdentityIDNEQ?: string | null;
  businessAccountIdentityIDNotIn?: ReadonlyArray<string> | null;
  businessAccountIdentityIDNotNil?: boolean | null;
  complyCubeClientID?: string | null;
  complyCubeClientIDContains?: string | null;
  complyCubeClientIDContainsFold?: string | null;
  complyCubeClientIDEqualFold?: string | null;
  complyCubeClientIDGT?: string | null;
  complyCubeClientIDGTE?: string | null;
  complyCubeClientIDHasPrefix?: string | null;
  complyCubeClientIDHasSuffix?: string | null;
  complyCubeClientIDIn?: ReadonlyArray<string> | null;
  complyCubeClientIDIsNil?: boolean | null;
  complyCubeClientIDLT?: string | null;
  complyCubeClientIDLTE?: string | null;
  complyCubeClientIDNEQ?: string | null;
  complyCubeClientIDNotIn?: ReadonlyArray<string> | null;
  complyCubeClientIDNotNil?: boolean | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  dateOfBirth?: any | null;
  dateOfBirthGT?: any | null;
  dateOfBirthGTE?: any | null;
  dateOfBirthIn?: ReadonlyArray<any> | null;
  dateOfBirthIsNil?: boolean | null;
  dateOfBirthLT?: any | null;
  dateOfBirthLTE?: any | null;
  dateOfBirthNEQ?: any | null;
  dateOfBirthNotIn?: ReadonlyArray<any> | null;
  dateOfBirthNotNil?: boolean | null;
  documentIssueCountry?: string | null;
  documentIssueCountryContains?: string | null;
  documentIssueCountryContainsFold?: string | null;
  documentIssueCountryEqualFold?: string | null;
  documentIssueCountryGT?: string | null;
  documentIssueCountryGTE?: string | null;
  documentIssueCountryHasPrefix?: string | null;
  documentIssueCountryHasSuffix?: string | null;
  documentIssueCountryIn?: ReadonlyArray<string> | null;
  documentIssueCountryIsNil?: boolean | null;
  documentIssueCountryLT?: string | null;
  documentIssueCountryLTE?: string | null;
  documentIssueCountryNEQ?: string | null;
  documentIssueCountryNotIn?: ReadonlyArray<string> | null;
  documentIssueCountryNotNil?: boolean | null;
  documentNumber?: string | null;
  documentNumberContains?: string | null;
  documentNumberContainsFold?: string | null;
  documentNumberEqualFold?: string | null;
  documentNumberGT?: string | null;
  documentNumberGTE?: string | null;
  documentNumberHasPrefix?: string | null;
  documentNumberHasSuffix?: string | null;
  documentNumberIn?: ReadonlyArray<string> | null;
  documentNumberIsNil?: boolean | null;
  documentNumberLT?: string | null;
  documentNumberLTE?: string | null;
  documentNumberNEQ?: string | null;
  documentNumberNotIn?: ReadonlyArray<string> | null;
  documentNumberNotNil?: boolean | null;
  documentType?: string | null;
  documentTypeContains?: string | null;
  documentTypeContainsFold?: string | null;
  documentTypeEqualFold?: string | null;
  documentTypeGT?: string | null;
  documentTypeGTE?: string | null;
  documentTypeHasPrefix?: string | null;
  documentTypeHasSuffix?: string | null;
  documentTypeIn?: ReadonlyArray<string> | null;
  documentTypeIsNil?: boolean | null;
  documentTypeLT?: string | null;
  documentTypeLTE?: string | null;
  documentTypeNEQ?: string | null;
  documentTypeNotIn?: ReadonlyArray<string> | null;
  documentTypeNotNil?: boolean | null;
  email?: string | null;
  emailContains?: string | null;
  emailContainsFold?: string | null;
  emailEqualFold?: string | null;
  emailGT?: string | null;
  emailGTE?: string | null;
  emailHasPrefix?: string | null;
  emailHasSuffix?: string | null;
  emailIn?: ReadonlyArray<string> | null;
  emailIsNil?: boolean | null;
  emailLT?: string | null;
  emailLTE?: string | null;
  emailNEQ?: string | null;
  emailNotIn?: ReadonlyArray<string> | null;
  emailNotNil?: boolean | null;
  firstName?: string | null;
  firstNameContains?: string | null;
  firstNameContainsFold?: string | null;
  firstNameEqualFold?: string | null;
  firstNameGT?: string | null;
  firstNameGTE?: string | null;
  firstNameHasPrefix?: string | null;
  firstNameHasSuffix?: string | null;
  firstNameIn?: ReadonlyArray<string> | null;
  firstNameIsNil?: boolean | null;
  firstNameLT?: string | null;
  firstNameLTE?: string | null;
  firstNameNEQ?: string | null;
  firstNameNotIn?: ReadonlyArray<string> | null;
  firstNameNotNil?: boolean | null;
  hasBusinessAccountIdentity?: boolean | null;
  hasBusinessAccountIdentityWith?: ReadonlyArray<BusinessAccountIdentityWhereInput> | null;
  hasCurrentKyc?: boolean | null;
  hasCurrentKycWith?: ReadonlyArray<KYCAttemptWhereInput> | null;
  hasKycAttempts?: boolean | null;
  hasKycAttemptsWith?: ReadonlyArray<KYCAttemptWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  kycAttemptID?: string | null;
  kycAttemptIDContains?: string | null;
  kycAttemptIDContainsFold?: string | null;
  kycAttemptIDEqualFold?: string | null;
  kycAttemptIDGT?: string | null;
  kycAttemptIDGTE?: string | null;
  kycAttemptIDHasPrefix?: string | null;
  kycAttemptIDHasSuffix?: string | null;
  kycAttemptIDIn?: ReadonlyArray<string> | null;
  kycAttemptIDIsNil?: boolean | null;
  kycAttemptIDLT?: string | null;
  kycAttemptIDLTE?: string | null;
  kycAttemptIDNEQ?: string | null;
  kycAttemptIDNotIn?: ReadonlyArray<string> | null;
  kycAttemptIDNotNil?: boolean | null;
  kycReviewState?: UserIdentityKycReviewState | null;
  kycReviewStateIn?: ReadonlyArray<UserIdentityKycReviewState> | null;
  kycReviewStateNEQ?: UserIdentityKycReviewState | null;
  kycReviewStateNotIn?: ReadonlyArray<UserIdentityKycReviewState> | null;
  lastName?: string | null;
  lastNameContains?: string | null;
  lastNameContainsFold?: string | null;
  lastNameEqualFold?: string | null;
  lastNameGT?: string | null;
  lastNameGTE?: string | null;
  lastNameHasPrefix?: string | null;
  lastNameHasSuffix?: string | null;
  lastNameIn?: ReadonlyArray<string> | null;
  lastNameIsNil?: boolean | null;
  lastNameLT?: string | null;
  lastNameLTE?: string | null;
  lastNameNEQ?: string | null;
  lastNameNotIn?: ReadonlyArray<string> | null;
  lastNameNotNil?: boolean | null;
  nationality?: string | null;
  nationalityContains?: string | null;
  nationalityContainsFold?: string | null;
  nationalityEqualFold?: string | null;
  nationalityGT?: string | null;
  nationalityGTE?: string | null;
  nationalityHasPrefix?: string | null;
  nationalityHasSuffix?: string | null;
  nationalityIn?: ReadonlyArray<string> | null;
  nationalityIsNil?: boolean | null;
  nationalityLT?: string | null;
  nationalityLTE?: string | null;
  nationalityNEQ?: string | null;
  nationalityNotIn?: ReadonlyArray<string> | null;
  nationalityNotNil?: boolean | null;
  not?: UserIdentityWhereInput | null;
  or?: ReadonlyArray<UserIdentityWhereInput> | null;
  type?: UserIdentityType | null;
  typeIn?: ReadonlyArray<UserIdentityType> | null;
  typeNEQ?: UserIdentityType | null;
  typeNotIn?: ReadonlyArray<UserIdentityType> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
  userID?: string | null;
  userIDContains?: string | null;
  userIDContainsFold?: string | null;
  userIDEqualFold?: string | null;
  userIDGT?: string | null;
  userIDGTE?: string | null;
  userIDHasPrefix?: string | null;
  userIDHasSuffix?: string | null;
  userIDIn?: ReadonlyArray<string> | null;
  userIDLT?: string | null;
  userIDLTE?: string | null;
  userIDNEQ?: string | null;
  userIDNotIn?: ReadonlyArray<string> | null;
};
export type BusinessAccountIdentityWhereInput = {
  accountID?: string | null;
  accountIDContains?: string | null;
  accountIDContainsFold?: string | null;
  accountIDEqualFold?: string | null;
  accountIDGT?: string | null;
  accountIDGTE?: string | null;
  accountIDHasPrefix?: string | null;
  accountIDHasSuffix?: string | null;
  accountIDIn?: ReadonlyArray<string> | null;
  accountIDIsNil?: boolean | null;
  accountIDLT?: string | null;
  accountIDLTE?: string | null;
  accountIDNEQ?: string | null;
  accountIDNotIn?: ReadonlyArray<string> | null;
  accountIDNotNil?: boolean | null;
  and?: ReadonlyArray<BusinessAccountIdentityWhereInput> | null;
  businessType?: BusinessAccountIdentityBusinessType | null;
  businessTypeIn?: ReadonlyArray<BusinessAccountIdentityBusinessType> | null;
  businessTypeNEQ?: BusinessAccountIdentityBusinessType | null;
  businessTypeNotIn?: ReadonlyArray<BusinessAccountIdentityBusinessType> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  currentKybAttemptID?: string | null;
  currentKybAttemptIDContains?: string | null;
  currentKybAttemptIDContainsFold?: string | null;
  currentKybAttemptIDEqualFold?: string | null;
  currentKybAttemptIDGT?: string | null;
  currentKybAttemptIDGTE?: string | null;
  currentKybAttemptIDHasPrefix?: string | null;
  currentKybAttemptIDHasSuffix?: string | null;
  currentKybAttemptIDIn?: ReadonlyArray<string> | null;
  currentKybAttemptIDIsNil?: boolean | null;
  currentKybAttemptIDLT?: string | null;
  currentKybAttemptIDLTE?: string | null;
  currentKybAttemptIDNEQ?: string | null;
  currentKybAttemptIDNotIn?: ReadonlyArray<string> | null;
  currentKybAttemptIDNotNil?: boolean | null;
  dateOfIncorporation?: any | null;
  dateOfIncorporationGT?: any | null;
  dateOfIncorporationGTE?: any | null;
  dateOfIncorporationIn?: ReadonlyArray<any> | null;
  dateOfIncorporationIsNil?: boolean | null;
  dateOfIncorporationLT?: any | null;
  dateOfIncorporationLTE?: any | null;
  dateOfIncorporationNEQ?: any | null;
  dateOfIncorporationNotIn?: ReadonlyArray<any> | null;
  dateOfIncorporationNotNil?: boolean | null;
  doingBusinessAsName?: string | null;
  doingBusinessAsNameContains?: string | null;
  doingBusinessAsNameContainsFold?: string | null;
  doingBusinessAsNameEqualFold?: string | null;
  doingBusinessAsNameGT?: string | null;
  doingBusinessAsNameGTE?: string | null;
  doingBusinessAsNameHasPrefix?: string | null;
  doingBusinessAsNameHasSuffix?: string | null;
  doingBusinessAsNameIn?: ReadonlyArray<string> | null;
  doingBusinessAsNameIsNil?: boolean | null;
  doingBusinessAsNameLT?: string | null;
  doingBusinessAsNameLTE?: string | null;
  doingBusinessAsNameNEQ?: string | null;
  doingBusinessAsNameNotIn?: ReadonlyArray<string> | null;
  doingBusinessAsNameNotNil?: boolean | null;
  email?: string | null;
  emailContains?: string | null;
  emailContainsFold?: string | null;
  emailEqualFold?: string | null;
  emailGT?: string | null;
  emailGTE?: string | null;
  emailHasPrefix?: string | null;
  emailHasSuffix?: string | null;
  emailIn?: ReadonlyArray<string> | null;
  emailIsNil?: boolean | null;
  emailLT?: string | null;
  emailLTE?: string | null;
  emailNEQ?: string | null;
  emailNotIn?: ReadonlyArray<string> | null;
  emailNotNil?: boolean | null;
  finalState?: BusinessAccountIdentityFinalState | null;
  finalStateIn?: ReadonlyArray<BusinessAccountIdentityFinalState> | null;
  finalStateNEQ?: BusinessAccountIdentityFinalState | null;
  finalStateNotIn?: ReadonlyArray<BusinessAccountIdentityFinalState> | null;
  fullLegalName?: string | null;
  fullLegalNameContains?: string | null;
  fullLegalNameContainsFold?: string | null;
  fullLegalNameEqualFold?: string | null;
  fullLegalNameGT?: string | null;
  fullLegalNameGTE?: string | null;
  fullLegalNameHasPrefix?: string | null;
  fullLegalNameHasSuffix?: string | null;
  fullLegalNameIn?: ReadonlyArray<string> | null;
  fullLegalNameIsNil?: boolean | null;
  fullLegalNameLT?: string | null;
  fullLegalNameLTE?: string | null;
  fullLegalNameNEQ?: string | null;
  fullLegalNameNotIn?: ReadonlyArray<string> | null;
  fullLegalNameNotNil?: boolean | null;
  hasCurrentKyb?: boolean | null;
  hasCurrentKybWith?: ReadonlyArray<KYBAttemptWhereInput> | null;
  hasKybAttempts?: boolean | null;
  hasKybAttemptsWith?: ReadonlyArray<KYBAttemptWhereInput> | null;
  hasUserIdentities?: boolean | null;
  hasUserIdentitiesWith?: ReadonlyArray<UserIdentityWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  incorporationCountry?: string | null;
  incorporationCountryContains?: string | null;
  incorporationCountryContainsFold?: string | null;
  incorporationCountryEqualFold?: string | null;
  incorporationCountryGT?: string | null;
  incorporationCountryGTE?: string | null;
  incorporationCountryHasPrefix?: string | null;
  incorporationCountryHasSuffix?: string | null;
  incorporationCountryIn?: ReadonlyArray<string> | null;
  incorporationCountryIsNil?: boolean | null;
  incorporationCountryLT?: string | null;
  incorporationCountryLTE?: string | null;
  incorporationCountryNEQ?: string | null;
  incorporationCountryNotIn?: ReadonlyArray<string> | null;
  incorporationCountryNotNil?: boolean | null;
  incorporationNumber?: string | null;
  incorporationNumberContains?: string | null;
  incorporationNumberContainsFold?: string | null;
  incorporationNumberEqualFold?: string | null;
  incorporationNumberGT?: string | null;
  incorporationNumberGTE?: string | null;
  incorporationNumberHasPrefix?: string | null;
  incorporationNumberHasSuffix?: string | null;
  incorporationNumberIn?: ReadonlyArray<string> | null;
  incorporationNumberIsNil?: boolean | null;
  incorporationNumberLT?: string | null;
  incorporationNumberLTE?: string | null;
  incorporationNumberNEQ?: string | null;
  incorporationNumberNotIn?: ReadonlyArray<string> | null;
  incorporationNumberNotNil?: boolean | null;
  legalAddress?: string | null;
  legalAddressContains?: string | null;
  legalAddressContainsFold?: string | null;
  legalAddressEqualFold?: string | null;
  legalAddressGT?: string | null;
  legalAddressGTE?: string | null;
  legalAddressHasPrefix?: string | null;
  legalAddressHasSuffix?: string | null;
  legalAddressIn?: ReadonlyArray<string> | null;
  legalAddressIsNil?: boolean | null;
  legalAddressLT?: string | null;
  legalAddressLTE?: string | null;
  legalAddressNEQ?: string | null;
  legalAddressNotIn?: ReadonlyArray<string> | null;
  legalAddressNotNil?: boolean | null;
  not?: BusinessAccountIdentityWhereInput | null;
  or?: ReadonlyArray<BusinessAccountIdentityWhereInput> | null;
  phone?: string | null;
  phoneContains?: string | null;
  phoneContainsFold?: string | null;
  phoneEqualFold?: string | null;
  phoneGT?: string | null;
  phoneGTE?: string | null;
  phoneHasPrefix?: string | null;
  phoneHasSuffix?: string | null;
  phoneIn?: ReadonlyArray<string> | null;
  phoneIsNil?: boolean | null;
  phoneLT?: string | null;
  phoneLTE?: string | null;
  phoneNEQ?: string | null;
  phoneNotIn?: ReadonlyArray<string> | null;
  phoneNotNil?: boolean | null;
  primaryAddress?: string | null;
  primaryAddressContains?: string | null;
  primaryAddressContainsFold?: string | null;
  primaryAddressEqualFold?: string | null;
  primaryAddressGT?: string | null;
  primaryAddressGTE?: string | null;
  primaryAddressHasPrefix?: string | null;
  primaryAddressHasSuffix?: string | null;
  primaryAddressIn?: ReadonlyArray<string> | null;
  primaryAddressIsNil?: boolean | null;
  primaryAddressLT?: string | null;
  primaryAddressLTE?: string | null;
  primaryAddressNEQ?: string | null;
  primaryAddressNotIn?: ReadonlyArray<string> | null;
  primaryAddressNotNil?: boolean | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
  website?: string | null;
  websiteContains?: string | null;
  websiteContainsFold?: string | null;
  websiteEqualFold?: string | null;
  websiteGT?: string | null;
  websiteGTE?: string | null;
  websiteHasPrefix?: string | null;
  websiteHasSuffix?: string | null;
  websiteIn?: ReadonlyArray<string> | null;
  websiteIsNil?: boolean | null;
  websiteLT?: string | null;
  websiteLTE?: string | null;
  websiteNEQ?: string | null;
  websiteNotIn?: ReadonlyArray<string> | null;
  websiteNotNil?: boolean | null;
};
export type KYBAttemptWhereInput = {
  and?: ReadonlyArray<KYBAttemptWhereInput> | null;
  businessAccountIdentityID?: string | null;
  businessAccountIdentityIDContains?: string | null;
  businessAccountIdentityIDContainsFold?: string | null;
  businessAccountIdentityIDEqualFold?: string | null;
  businessAccountIdentityIDGT?: string | null;
  businessAccountIdentityIDGTE?: string | null;
  businessAccountIdentityIDHasPrefix?: string | null;
  businessAccountIdentityIDHasSuffix?: string | null;
  businessAccountIdentityIDIn?: ReadonlyArray<string> | null;
  businessAccountIdentityIDLT?: string | null;
  businessAccountIdentityIDLTE?: string | null;
  businessAccountIdentityIDNEQ?: string | null;
  businessAccountIdentityIDNotIn?: ReadonlyArray<string> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  hasActionLogs?: boolean | null;
  hasActionLogsWith?: ReadonlyArray<KYBActionLogWhereInput> | null;
  hasBusinessAccountIdentity?: boolean | null;
  hasBusinessAccountIdentityWith?: ReadonlyArray<BusinessAccountIdentityWhereInput> | null;
  hasDocuments?: boolean | null;
  hasDocumentsWith?: ReadonlyArray<KYBDocumentWhereInput> | null;
  hasReviews?: boolean | null;
  hasReviewsWith?: ReadonlyArray<KYBReviewWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: KYBAttemptWhereInput | null;
  or?: ReadonlyArray<KYBAttemptWhereInput> | null;
  state?: KYBAttemptState | null;
  stateIn?: ReadonlyArray<KYBAttemptState> | null;
  stateNEQ?: KYBAttemptState | null;
  stateNotIn?: ReadonlyArray<KYBAttemptState> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type KYBReviewWhereInput = {
  action?: KYBReviewAction | null;
  actionIn?: ReadonlyArray<KYBReviewAction> | null;
  actionNEQ?: KYBReviewAction | null;
  actionNotIn?: ReadonlyArray<KYBReviewAction> | null;
  and?: ReadonlyArray<KYBReviewWhereInput> | null;
  comment?: string | null;
  commentContains?: string | null;
  commentContainsFold?: string | null;
  commentEqualFold?: string | null;
  commentGT?: string | null;
  commentGTE?: string | null;
  commentHasPrefix?: string | null;
  commentHasSuffix?: string | null;
  commentIn?: ReadonlyArray<string> | null;
  commentIsNil?: boolean | null;
  commentLT?: string | null;
  commentLTE?: string | null;
  commentNEQ?: string | null;
  commentNotIn?: ReadonlyArray<string> | null;
  commentNotNil?: boolean | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  hasKybAttempt?: boolean | null;
  hasKybAttemptWith?: ReadonlyArray<KYBAttemptWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  kybAttemptID?: string | null;
  kybAttemptIDContains?: string | null;
  kybAttemptIDContainsFold?: string | null;
  kybAttemptIDEqualFold?: string | null;
  kybAttemptIDGT?: string | null;
  kybAttemptIDGTE?: string | null;
  kybAttemptIDHasPrefix?: string | null;
  kybAttemptIDHasSuffix?: string | null;
  kybAttemptIDIn?: ReadonlyArray<string> | null;
  kybAttemptIDLT?: string | null;
  kybAttemptIDLTE?: string | null;
  kybAttemptIDNEQ?: string | null;
  kybAttemptIDNotIn?: ReadonlyArray<string> | null;
  not?: KYBReviewWhereInput | null;
  or?: ReadonlyArray<KYBReviewWhereInput> | null;
  reviewerUserID?: string | null;
  reviewerUserIDContains?: string | null;
  reviewerUserIDContainsFold?: string | null;
  reviewerUserIDEqualFold?: string | null;
  reviewerUserIDGT?: string | null;
  reviewerUserIDGTE?: string | null;
  reviewerUserIDHasPrefix?: string | null;
  reviewerUserIDHasSuffix?: string | null;
  reviewerUserIDIn?: ReadonlyArray<string> | null;
  reviewerUserIDLT?: string | null;
  reviewerUserIDLTE?: string | null;
  reviewerUserIDNEQ?: string | null;
  reviewerUserIDNotIn?: ReadonlyArray<string> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type KYBDocumentWhereInput = {
  and?: ReadonlyArray<KYBDocumentWhereInput> | null;
  comment?: string | null;
  commentContains?: string | null;
  commentContainsFold?: string | null;
  commentEqualFold?: string | null;
  commentGT?: string | null;
  commentGTE?: string | null;
  commentHasPrefix?: string | null;
  commentHasSuffix?: string | null;
  commentIn?: ReadonlyArray<string> | null;
  commentIsNil?: boolean | null;
  commentLT?: string | null;
  commentLTE?: string | null;
  commentNEQ?: string | null;
  commentNotIn?: ReadonlyArray<string> | null;
  commentNotNil?: boolean | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  fileName?: string | null;
  fileNameContains?: string | null;
  fileNameContainsFold?: string | null;
  fileNameEqualFold?: string | null;
  fileNameGT?: string | null;
  fileNameGTE?: string | null;
  fileNameHasPrefix?: string | null;
  fileNameHasSuffix?: string | null;
  fileNameIn?: ReadonlyArray<string> | null;
  fileNameIsNil?: boolean | null;
  fileNameLT?: string | null;
  fileNameLTE?: string | null;
  fileNameNEQ?: string | null;
  fileNameNotIn?: ReadonlyArray<string> | null;
  fileNameNotNil?: boolean | null;
  hasKybAttempt?: boolean | null;
  hasKybAttemptWith?: ReadonlyArray<KYBAttemptWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  kybAttemptID?: string | null;
  kybAttemptIDContains?: string | null;
  kybAttemptIDContainsFold?: string | null;
  kybAttemptIDEqualFold?: string | null;
  kybAttemptIDGT?: string | null;
  kybAttemptIDGTE?: string | null;
  kybAttemptIDHasPrefix?: string | null;
  kybAttemptIDHasSuffix?: string | null;
  kybAttemptIDIn?: ReadonlyArray<string> | null;
  kybAttemptIDLT?: string | null;
  kybAttemptIDLTE?: string | null;
  kybAttemptIDNEQ?: string | null;
  kybAttemptIDNotIn?: ReadonlyArray<string> | null;
  not?: KYBDocumentWhereInput | null;
  or?: ReadonlyArray<KYBDocumentWhereInput> | null;
  type?: KYBDocumentType | null;
  typeIn?: ReadonlyArray<KYBDocumentType> | null;
  typeNEQ?: KYBDocumentType | null;
  typeNotIn?: ReadonlyArray<KYBDocumentType> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
  uploaderRole?: KYBDocumentUploaderRole | null;
  uploaderRoleIn?: ReadonlyArray<KYBDocumentUploaderRole> | null;
  uploaderRoleNEQ?: KYBDocumentUploaderRole | null;
  uploaderRoleNotIn?: ReadonlyArray<KYBDocumentUploaderRole> | null;
  uploaderUserID?: string | null;
  uploaderUserIDContains?: string | null;
  uploaderUserIDContainsFold?: string | null;
  uploaderUserIDEqualFold?: string | null;
  uploaderUserIDGT?: string | null;
  uploaderUserIDGTE?: string | null;
  uploaderUserIDHasPrefix?: string | null;
  uploaderUserIDHasSuffix?: string | null;
  uploaderUserIDIn?: ReadonlyArray<string> | null;
  uploaderUserIDLT?: string | null;
  uploaderUserIDLTE?: string | null;
  uploaderUserIDNEQ?: string | null;
  uploaderUserIDNotIn?: ReadonlyArray<string> | null;
  url?: string | null;
  urlContains?: string | null;
  urlContainsFold?: string | null;
  urlEqualFold?: string | null;
  urlGT?: string | null;
  urlGTE?: string | null;
  urlHasPrefix?: string | null;
  urlHasSuffix?: string | null;
  urlIn?: ReadonlyArray<string> | null;
  urlLT?: string | null;
  urlLTE?: string | null;
  urlNEQ?: string | null;
  urlNotIn?: ReadonlyArray<string> | null;
};
export type KYBActionLogWhereInput = {
  action?: KYBActionLogAction | null;
  actionIn?: ReadonlyArray<KYBActionLogAction> | null;
  actionNEQ?: KYBActionLogAction | null;
  actionNotIn?: ReadonlyArray<KYBActionLogAction> | null;
  and?: ReadonlyArray<KYBActionLogWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  currentAttemptState?: KYBActionLogCurrentAttemptState | null;
  currentAttemptStateIn?: ReadonlyArray<KYBActionLogCurrentAttemptState> | null;
  currentAttemptStateIsNil?: boolean | null;
  currentAttemptStateNEQ?: KYBActionLogCurrentAttemptState | null;
  currentAttemptStateNotIn?: ReadonlyArray<KYBActionLogCurrentAttemptState> | null;
  currentAttemptStateNotNil?: boolean | null;
  data?: string | null;
  dataContains?: string | null;
  dataContainsFold?: string | null;
  dataEqualFold?: string | null;
  dataGT?: string | null;
  dataGTE?: string | null;
  dataHasPrefix?: string | null;
  dataHasSuffix?: string | null;
  dataIn?: ReadonlyArray<string> | null;
  dataIsNil?: boolean | null;
  dataLT?: string | null;
  dataLTE?: string | null;
  dataNEQ?: string | null;
  dataNotIn?: ReadonlyArray<string> | null;
  dataNotNil?: boolean | null;
  hasKybAttempt?: boolean | null;
  hasKybAttemptWith?: ReadonlyArray<KYBAttemptWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  kybAttemptID?: string | null;
  kybAttemptIDContains?: string | null;
  kybAttemptIDContainsFold?: string | null;
  kybAttemptIDEqualFold?: string | null;
  kybAttemptIDGT?: string | null;
  kybAttemptIDGTE?: string | null;
  kybAttemptIDHasPrefix?: string | null;
  kybAttemptIDHasSuffix?: string | null;
  kybAttemptIDIn?: ReadonlyArray<string> | null;
  kybAttemptIDLT?: string | null;
  kybAttemptIDLTE?: string | null;
  kybAttemptIDNEQ?: string | null;
  kybAttemptIDNotIn?: ReadonlyArray<string> | null;
  message?: string | null;
  messageContains?: string | null;
  messageContainsFold?: string | null;
  messageEqualFold?: string | null;
  messageGT?: string | null;
  messageGTE?: string | null;
  messageHasPrefix?: string | null;
  messageHasSuffix?: string | null;
  messageIn?: ReadonlyArray<string> | null;
  messageIsNil?: boolean | null;
  messageLT?: string | null;
  messageLTE?: string | null;
  messageNEQ?: string | null;
  messageNotIn?: ReadonlyArray<string> | null;
  messageNotNil?: boolean | null;
  not?: KYBActionLogWhereInput | null;
  or?: ReadonlyArray<KYBActionLogWhereInput> | null;
  prevAttemptState?: KYBActionLogPrevAttemptState | null;
  prevAttemptStateIn?: ReadonlyArray<KYBActionLogPrevAttemptState> | null;
  prevAttemptStateIsNil?: boolean | null;
  prevAttemptStateNEQ?: KYBActionLogPrevAttemptState | null;
  prevAttemptStateNotIn?: ReadonlyArray<KYBActionLogPrevAttemptState> | null;
  prevAttemptStateNotNil?: boolean | null;
  subjectEmail?: string | null;
  subjectEmailContains?: string | null;
  subjectEmailContainsFold?: string | null;
  subjectEmailEqualFold?: string | null;
  subjectEmailGT?: string | null;
  subjectEmailGTE?: string | null;
  subjectEmailHasPrefix?: string | null;
  subjectEmailHasSuffix?: string | null;
  subjectEmailIn?: ReadonlyArray<string> | null;
  subjectEmailIsNil?: boolean | null;
  subjectEmailLT?: string | null;
  subjectEmailLTE?: string | null;
  subjectEmailNEQ?: string | null;
  subjectEmailNotIn?: ReadonlyArray<string> | null;
  subjectEmailNotNil?: boolean | null;
  subjectType?: KYBActionLogSubjectType | null;
  subjectTypeIn?: ReadonlyArray<KYBActionLogSubjectType> | null;
  subjectTypeNEQ?: KYBActionLogSubjectType | null;
  subjectTypeNotIn?: ReadonlyArray<KYBActionLogSubjectType> | null;
  subjectUserID?: string | null;
  subjectUserIDContains?: string | null;
  subjectUserIDContainsFold?: string | null;
  subjectUserIDEqualFold?: string | null;
  subjectUserIDGT?: string | null;
  subjectUserIDGTE?: string | null;
  subjectUserIDHasPrefix?: string | null;
  subjectUserIDHasSuffix?: string | null;
  subjectUserIDIn?: ReadonlyArray<string> | null;
  subjectUserIDLT?: string | null;
  subjectUserIDLTE?: string | null;
  subjectUserIDNEQ?: string | null;
  subjectUserIDNotIn?: ReadonlyArray<string> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type KYCAttemptWhereInput = {
  and?: ReadonlyArray<KYCAttemptWhereInput> | null;
  countryOfResidence?: string | null;
  countryOfResidenceContains?: string | null;
  countryOfResidenceContainsFold?: string | null;
  countryOfResidenceEqualFold?: string | null;
  countryOfResidenceGT?: string | null;
  countryOfResidenceGTE?: string | null;
  countryOfResidenceHasPrefix?: string | null;
  countryOfResidenceHasSuffix?: string | null;
  countryOfResidenceIn?: ReadonlyArray<string> | null;
  countryOfResidenceIsNil?: boolean | null;
  countryOfResidenceLT?: string | null;
  countryOfResidenceLTE?: string | null;
  countryOfResidenceNEQ?: string | null;
  countryOfResidenceNotIn?: ReadonlyArray<string> | null;
  countryOfResidenceNotNil?: boolean | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  extractedInfoID?: string | null;
  extractedInfoIDContains?: string | null;
  extractedInfoIDContainsFold?: string | null;
  extractedInfoIDEqualFold?: string | null;
  extractedInfoIDGT?: string | null;
  extractedInfoIDGTE?: string | null;
  extractedInfoIDHasPrefix?: string | null;
  extractedInfoIDHasSuffix?: string | null;
  extractedInfoIDIn?: ReadonlyArray<string> | null;
  extractedInfoIDIsNil?: boolean | null;
  extractedInfoIDLT?: string | null;
  extractedInfoIDLTE?: string | null;
  extractedInfoIDNEQ?: string | null;
  extractedInfoIDNotIn?: ReadonlyArray<string> | null;
  extractedInfoIDNotNil?: boolean | null;
  hasAddress?: boolean | null;
  hasAddressWith?: ReadonlyArray<AddressWhereInput> | null;
  hasComplyCubeChecks?: boolean | null;
  hasComplyCubeChecksWith?: ReadonlyArray<ComplyCubeCheckWhereInput> | null;
  hasExtractedInfo?: boolean | null;
  hasExtractedInfoWith?: ReadonlyArray<KYCExtractedInfoWhereInput> | null;
  hasKycActionLogs?: boolean | null;
  hasKycActionLogsWith?: ReadonlyArray<KYCActionLogWhereInput> | null;
  hasKycReviewDocuments?: boolean | null;
  hasKycReviewDocumentsWith?: ReadonlyArray<KYCReviewDocumentWhereInput> | null;
  hasKycReviews?: boolean | null;
  hasKycReviewsWith?: ReadonlyArray<KYCReviewWhereInput> | null;
  hasKycTags?: boolean | null;
  hasKycTagsWith?: ReadonlyArray<KYCTagWhereInput> | null;
  hasQuestionAnswers?: boolean | null;
  hasQuestionAnswersWith?: ReadonlyArray<QuestionAnswerWhereInput> | null;
  hasRiskScore?: boolean | null;
  hasRiskScoreWith?: ReadonlyArray<KYCRiskScoreWhereInput> | null;
  hasUserIdentity?: boolean | null;
  hasUserIdentityWith?: ReadonlyArray<UserIdentityWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  location?: string | null;
  locationContains?: string | null;
  locationContainsFold?: string | null;
  locationEqualFold?: string | null;
  locationGT?: string | null;
  locationGTE?: string | null;
  locationHasPrefix?: string | null;
  locationHasSuffix?: string | null;
  locationIn?: ReadonlyArray<string> | null;
  locationIsNil?: boolean | null;
  locationLT?: string | null;
  locationLTE?: string | null;
  locationNEQ?: string | null;
  locationNotIn?: ReadonlyArray<string> | null;
  locationNotNil?: boolean | null;
  not?: KYCAttemptWhereInput | null;
  or?: ReadonlyArray<KYCAttemptWhereInput> | null;
  state?: KYCAttemptState | null;
  stateIn?: ReadonlyArray<KYCAttemptState> | null;
  stateNEQ?: KYCAttemptState | null;
  stateNotIn?: ReadonlyArray<KYCAttemptState> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
  userIdentityID?: string | null;
  userIdentityIDContains?: string | null;
  userIdentityIDContainsFold?: string | null;
  userIdentityIDEqualFold?: string | null;
  userIdentityIDGT?: string | null;
  userIdentityIDGTE?: string | null;
  userIdentityIDHasPrefix?: string | null;
  userIdentityIDHasSuffix?: string | null;
  userIdentityIDIn?: ReadonlyArray<string> | null;
  userIdentityIDLT?: string | null;
  userIdentityIDLTE?: string | null;
  userIdentityIDNEQ?: string | null;
  userIdentityIDNotIn?: ReadonlyArray<string> | null;
  userType?: KYCAttemptUserType | null;
  userTypeIn?: ReadonlyArray<KYCAttemptUserType> | null;
  userTypeNEQ?: KYCAttemptUserType | null;
  userTypeNotIn?: ReadonlyArray<KYCAttemptUserType> | null;
};
export type KYCTagWhereInput = {
  and?: ReadonlyArray<KYCTagWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  hasKycAttempts?: boolean | null;
  hasKycAttemptsWith?: ReadonlyArray<KYCAttemptWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: KYCTagWhereInput | null;
  or?: ReadonlyArray<KYCTagWhereInput> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
  value?: KYCTagValue | null;
  valueIn?: ReadonlyArray<KYCTagValue> | null;
  valueNEQ?: KYCTagValue | null;
  valueNotIn?: ReadonlyArray<KYCTagValue> | null;
};
export type ComplyCubeCheckWhereInput = {
  and?: ReadonlyArray<ComplyCubeCheckWhereInput> | null;
  ccCheckID?: string | null;
  ccCheckIDContains?: string | null;
  ccCheckIDContainsFold?: string | null;
  ccCheckIDEqualFold?: string | null;
  ccCheckIDGT?: string | null;
  ccCheckIDGTE?: string | null;
  ccCheckIDHasPrefix?: string | null;
  ccCheckIDHasSuffix?: string | null;
  ccCheckIDIn?: ReadonlyArray<string> | null;
  ccCheckIDLT?: string | null;
  ccCheckIDLTE?: string | null;
  ccCheckIDNEQ?: string | null;
  ccCheckIDNotIn?: ReadonlyArray<string> | null;
  ccDocumentID?: string | null;
  ccDocumentIDContains?: string | null;
  ccDocumentIDContainsFold?: string | null;
  ccDocumentIDEqualFold?: string | null;
  ccDocumentIDGT?: string | null;
  ccDocumentIDGTE?: string | null;
  ccDocumentIDHasPrefix?: string | null;
  ccDocumentIDHasSuffix?: string | null;
  ccDocumentIDIn?: ReadonlyArray<string> | null;
  ccDocumentIDIsNil?: boolean | null;
  ccDocumentIDLT?: string | null;
  ccDocumentIDLTE?: string | null;
  ccDocumentIDNEQ?: string | null;
  ccDocumentIDNotIn?: ReadonlyArray<string> | null;
  ccDocumentIDNotNil?: boolean | null;
  ccLivePhotoID?: string | null;
  ccLivePhotoIDContains?: string | null;
  ccLivePhotoIDContainsFold?: string | null;
  ccLivePhotoIDEqualFold?: string | null;
  ccLivePhotoIDGT?: string | null;
  ccLivePhotoIDGTE?: string | null;
  ccLivePhotoIDHasPrefix?: string | null;
  ccLivePhotoIDHasSuffix?: string | null;
  ccLivePhotoIDIn?: ReadonlyArray<string> | null;
  ccLivePhotoIDIsNil?: boolean | null;
  ccLivePhotoIDLT?: string | null;
  ccLivePhotoIDLTE?: string | null;
  ccLivePhotoIDNEQ?: string | null;
  ccLivePhotoIDNotIn?: ReadonlyArray<string> | null;
  ccLivePhotoIDNotNil?: boolean | null;
  ccOutcome?: ComplyCubeCheckCcOutcome | null;
  ccOutcomeIn?: ReadonlyArray<ComplyCubeCheckCcOutcome> | null;
  ccOutcomeIsNil?: boolean | null;
  ccOutcomeNEQ?: ComplyCubeCheckCcOutcome | null;
  ccOutcomeNotIn?: ReadonlyArray<ComplyCubeCheckCcOutcome> | null;
  ccOutcomeNotNil?: boolean | null;
  ccStatus?: ComplyCubeCheckCcStatus | null;
  ccStatusIn?: ReadonlyArray<ComplyCubeCheckCcStatus> | null;
  ccStatusIsNil?: boolean | null;
  ccStatusNEQ?: ComplyCubeCheckCcStatus | null;
  ccStatusNotIn?: ReadonlyArray<ComplyCubeCheckCcStatus> | null;
  ccStatusNotNil?: boolean | null;
  ccType?: ComplyCubeCheckCcType | null;
  ccTypeIn?: ReadonlyArray<ComplyCubeCheckCcType> | null;
  ccTypeNEQ?: ComplyCubeCheckCcType | null;
  ccTypeNotIn?: ReadonlyArray<ComplyCubeCheckCcType> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  hasKycAttempt?: boolean | null;
  hasKycAttemptWith?: ReadonlyArray<KYCAttemptWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  kycAttemptID?: string | null;
  kycAttemptIDContains?: string | null;
  kycAttemptIDContainsFold?: string | null;
  kycAttemptIDEqualFold?: string | null;
  kycAttemptIDGT?: string | null;
  kycAttemptIDGTE?: string | null;
  kycAttemptIDHasPrefix?: string | null;
  kycAttemptIDHasSuffix?: string | null;
  kycAttemptIDIn?: ReadonlyArray<string> | null;
  kycAttemptIDLT?: string | null;
  kycAttemptIDLTE?: string | null;
  kycAttemptIDNEQ?: string | null;
  kycAttemptIDNotIn?: ReadonlyArray<string> | null;
  not?: ComplyCubeCheckWhereInput | null;
  or?: ReadonlyArray<ComplyCubeCheckWhereInput> | null;
  sourceIP?: string | null;
  sourceIPContains?: string | null;
  sourceIPContainsFold?: string | null;
  sourceIPCountry?: string | null;
  sourceIPCountryContains?: string | null;
  sourceIPCountryContainsFold?: string | null;
  sourceIPCountryEqualFold?: string | null;
  sourceIPCountryGT?: string | null;
  sourceIPCountryGTE?: string | null;
  sourceIPCountryHasPrefix?: string | null;
  sourceIPCountryHasSuffix?: string | null;
  sourceIPCountryIn?: ReadonlyArray<string> | null;
  sourceIPCountryIsNil?: boolean | null;
  sourceIPCountryLT?: string | null;
  sourceIPCountryLTE?: string | null;
  sourceIPCountryNEQ?: string | null;
  sourceIPCountryNotIn?: ReadonlyArray<string> | null;
  sourceIPCountryNotNil?: boolean | null;
  sourceIPEqualFold?: string | null;
  sourceIPGT?: string | null;
  sourceIPGTE?: string | null;
  sourceIPHasPrefix?: string | null;
  sourceIPHasSuffix?: string | null;
  sourceIPIn?: ReadonlyArray<string> | null;
  sourceIPIsNil?: boolean | null;
  sourceIPLT?: string | null;
  sourceIPLTE?: string | null;
  sourceIPNEQ?: string | null;
  sourceIPNotIn?: ReadonlyArray<string> | null;
  sourceIPNotNil?: boolean | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type KYCReviewWhereInput = {
  action?: KYCReviewAction | null;
  actionIn?: ReadonlyArray<KYCReviewAction> | null;
  actionNEQ?: KYCReviewAction | null;
  actionNotIn?: ReadonlyArray<KYCReviewAction> | null;
  and?: ReadonlyArray<KYCReviewWhereInput> | null;
  comment?: string | null;
  commentContains?: string | null;
  commentContainsFold?: string | null;
  commentEqualFold?: string | null;
  commentGT?: string | null;
  commentGTE?: string | null;
  commentHasPrefix?: string | null;
  commentHasSuffix?: string | null;
  commentIn?: ReadonlyArray<string> | null;
  commentIsNil?: boolean | null;
  commentLT?: string | null;
  commentLTE?: string | null;
  commentNEQ?: string | null;
  commentNotIn?: ReadonlyArray<string> | null;
  commentNotNil?: boolean | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  hasDocument?: boolean | null;
  hasDocumentWith?: ReadonlyArray<KYCReviewDocumentWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  kycAttemptID?: string | null;
  kycAttemptIDContains?: string | null;
  kycAttemptIDContainsFold?: string | null;
  kycAttemptIDEqualFold?: string | null;
  kycAttemptIDGT?: string | null;
  kycAttemptIDGTE?: string | null;
  kycAttemptIDHasPrefix?: string | null;
  kycAttemptIDHasSuffix?: string | null;
  kycAttemptIDIn?: ReadonlyArray<string> | null;
  kycAttemptIDLT?: string | null;
  kycAttemptIDLTE?: string | null;
  kycAttemptIDNEQ?: string | null;
  kycAttemptIDNotIn?: ReadonlyArray<string> | null;
  not?: KYCReviewWhereInput | null;
  or?: ReadonlyArray<KYCReviewWhereInput> | null;
  reviewerUserID?: string | null;
  reviewerUserIDContains?: string | null;
  reviewerUserIDContainsFold?: string | null;
  reviewerUserIDEqualFold?: string | null;
  reviewerUserIDGT?: string | null;
  reviewerUserIDGTE?: string | null;
  reviewerUserIDHasPrefix?: string | null;
  reviewerUserIDHasSuffix?: string | null;
  reviewerUserIDIn?: ReadonlyArray<string> | null;
  reviewerUserIDLT?: string | null;
  reviewerUserIDLTE?: string | null;
  reviewerUserIDNEQ?: string | null;
  reviewerUserIDNotIn?: ReadonlyArray<string> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type KYCReviewDocumentWhereInput = {
  and?: ReadonlyArray<KYCReviewDocumentWhereInput> | null;
  comment?: string | null;
  commentContains?: string | null;
  commentContainsFold?: string | null;
  commentEqualFold?: string | null;
  commentGT?: string | null;
  commentGTE?: string | null;
  commentHasPrefix?: string | null;
  commentHasSuffix?: string | null;
  commentIn?: ReadonlyArray<string> | null;
  commentIsNil?: boolean | null;
  commentLT?: string | null;
  commentLTE?: string | null;
  commentNEQ?: string | null;
  commentNotIn?: ReadonlyArray<string> | null;
  commentNotNil?: boolean | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  fileName?: string | null;
  fileNameContains?: string | null;
  fileNameContainsFold?: string | null;
  fileNameEqualFold?: string | null;
  fileNameGT?: string | null;
  fileNameGTE?: string | null;
  fileNameHasPrefix?: string | null;
  fileNameHasSuffix?: string | null;
  fileNameIn?: ReadonlyArray<string> | null;
  fileNameIsNil?: boolean | null;
  fileNameLT?: string | null;
  fileNameLTE?: string | null;
  fileNameNEQ?: string | null;
  fileNameNotIn?: ReadonlyArray<string> | null;
  fileNameNotNil?: boolean | null;
  hasKycAttempt?: boolean | null;
  hasKycAttemptWith?: ReadonlyArray<KYCAttemptWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  kycAttemptID?: string | null;
  kycAttemptIDContains?: string | null;
  kycAttemptIDContainsFold?: string | null;
  kycAttemptIDEqualFold?: string | null;
  kycAttemptIDGT?: string | null;
  kycAttemptIDGTE?: string | null;
  kycAttemptIDHasPrefix?: string | null;
  kycAttemptIDHasSuffix?: string | null;
  kycAttemptIDIn?: ReadonlyArray<string> | null;
  kycAttemptIDLT?: string | null;
  kycAttemptIDLTE?: string | null;
  kycAttemptIDNEQ?: string | null;
  kycAttemptIDNotIn?: ReadonlyArray<string> | null;
  not?: KYCReviewDocumentWhereInput | null;
  or?: ReadonlyArray<KYCReviewDocumentWhereInput> | null;
  type?: KYCReviewDocumentType | null;
  typeIn?: ReadonlyArray<KYCReviewDocumentType> | null;
  typeNEQ?: KYCReviewDocumentType | null;
  typeNotIn?: ReadonlyArray<KYCReviewDocumentType> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
  uploaderUserID?: string | null;
  uploaderUserIDContains?: string | null;
  uploaderUserIDContainsFold?: string | null;
  uploaderUserIDEqualFold?: string | null;
  uploaderUserIDGT?: string | null;
  uploaderUserIDGTE?: string | null;
  uploaderUserIDHasPrefix?: string | null;
  uploaderUserIDHasSuffix?: string | null;
  uploaderUserIDIn?: ReadonlyArray<string> | null;
  uploaderUserIDLT?: string | null;
  uploaderUserIDLTE?: string | null;
  uploaderUserIDNEQ?: string | null;
  uploaderUserIDNotIn?: ReadonlyArray<string> | null;
  url?: string | null;
  urlContains?: string | null;
  urlContainsFold?: string | null;
  urlEqualFold?: string | null;
  urlGT?: string | null;
  urlGTE?: string | null;
  urlHasPrefix?: string | null;
  urlHasSuffix?: string | null;
  urlIn?: ReadonlyArray<string> | null;
  urlLT?: string | null;
  urlLTE?: string | null;
  urlNEQ?: string | null;
  urlNotIn?: ReadonlyArray<string> | null;
};
export type QuestionAnswerWhereInput = {
  and?: ReadonlyArray<QuestionAnswerWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  hasKycAttempt?: boolean | null;
  hasKycAttemptWith?: ReadonlyArray<KYCAttemptWhereInput> | null;
  hasOption?: boolean | null;
  hasOptionWith?: ReadonlyArray<QuestionAnswerOptionWhereInput> | null;
  hasQuestion?: boolean | null;
  hasQuestionWith?: ReadonlyArray<QuestionWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  kycAttemptID?: string | null;
  kycAttemptIDContains?: string | null;
  kycAttemptIDContainsFold?: string | null;
  kycAttemptIDEqualFold?: string | null;
  kycAttemptIDGT?: string | null;
  kycAttemptIDGTE?: string | null;
  kycAttemptIDHasPrefix?: string | null;
  kycAttemptIDHasSuffix?: string | null;
  kycAttemptIDIn?: ReadonlyArray<string> | null;
  kycAttemptIDIsNil?: boolean | null;
  kycAttemptIDLT?: string | null;
  kycAttemptIDLTE?: string | null;
  kycAttemptIDNEQ?: string | null;
  kycAttemptIDNotIn?: ReadonlyArray<string> | null;
  kycAttemptIDNotNil?: boolean | null;
  not?: QuestionAnswerWhereInput | null;
  optionID?: string | null;
  optionIDContains?: string | null;
  optionIDContainsFold?: string | null;
  optionIDEqualFold?: string | null;
  optionIDGT?: string | null;
  optionIDGTE?: string | null;
  optionIDHasPrefix?: string | null;
  optionIDHasSuffix?: string | null;
  optionIDIn?: ReadonlyArray<string> | null;
  optionIDLT?: string | null;
  optionIDLTE?: string | null;
  optionIDNEQ?: string | null;
  optionIDNotIn?: ReadonlyArray<string> | null;
  or?: ReadonlyArray<QuestionAnswerWhereInput> | null;
  questionID?: string | null;
  questionIDContains?: string | null;
  questionIDContainsFold?: string | null;
  questionIDEqualFold?: string | null;
  questionIDGT?: string | null;
  questionIDGTE?: string | null;
  questionIDHasPrefix?: string | null;
  questionIDHasSuffix?: string | null;
  questionIDIn?: ReadonlyArray<string> | null;
  questionIDLT?: string | null;
  questionIDLTE?: string | null;
  questionIDNEQ?: string | null;
  questionIDNotIn?: ReadonlyArray<string> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type QuestionAnswerOptionWhereInput = {
  and?: ReadonlyArray<QuestionAnswerOptionWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  display?: string | null;
  displayContains?: string | null;
  displayContainsFold?: string | null;
  displayEqualFold?: string | null;
  displayGT?: string | null;
  displayGTE?: string | null;
  displayHasPrefix?: string | null;
  displayHasSuffix?: string | null;
  displayIn?: ReadonlyArray<string> | null;
  displayLT?: string | null;
  displayLTE?: string | null;
  displayNEQ?: string | null;
  displayNotIn?: ReadonlyArray<string> | null;
  hasQuestion?: boolean | null;
  hasQuestionWith?: ReadonlyArray<QuestionWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: QuestionAnswerOptionWhereInput | null;
  or?: ReadonlyArray<QuestionAnswerOptionWhereInput> | null;
  questionID?: string | null;
  questionIDContains?: string | null;
  questionIDContainsFold?: string | null;
  questionIDEqualFold?: string | null;
  questionIDGT?: string | null;
  questionIDGTE?: string | null;
  questionIDHasPrefix?: string | null;
  questionIDHasSuffix?: string | null;
  questionIDIn?: ReadonlyArray<string> | null;
  questionIDLT?: string | null;
  questionIDLTE?: string | null;
  questionIDNEQ?: string | null;
  questionIDNotIn?: ReadonlyArray<string> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type QuestionWhereInput = {
  and?: ReadonlyArray<QuestionWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  display?: string | null;
  displayContains?: string | null;
  displayContainsFold?: string | null;
  displayEqualFold?: string | null;
  displayGT?: string | null;
  displayGTE?: string | null;
  displayHasPrefix?: string | null;
  displayHasSuffix?: string | null;
  displayIn?: ReadonlyArray<string> | null;
  displayLT?: string | null;
  displayLTE?: string | null;
  displayNEQ?: string | null;
  displayNotIn?: ReadonlyArray<string> | null;
  hasOptions?: boolean | null;
  hasOptionsWith?: ReadonlyArray<QuestionAnswerOptionWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: QuestionWhereInput | null;
  or?: ReadonlyArray<QuestionWhereInput> | null;
  questionSet?: QuestionQuestionSet | null;
  questionSetIn?: ReadonlyArray<QuestionQuestionSet> | null;
  questionSetNEQ?: QuestionQuestionSet | null;
  questionSetNotIn?: ReadonlyArray<QuestionQuestionSet> | null;
  type?: QuestionType | null;
  typeIn?: ReadonlyArray<QuestionType> | null;
  typeNEQ?: QuestionType | null;
  typeNotIn?: ReadonlyArray<QuestionType> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type KYCExtractedInfoWhereInput = {
  and?: ReadonlyArray<KYCExtractedInfoWhereInput> | null;
  ccCheckID?: string | null;
  ccCheckIDContains?: string | null;
  ccCheckIDContainsFold?: string | null;
  ccCheckIDEqualFold?: string | null;
  ccCheckIDGT?: string | null;
  ccCheckIDGTE?: string | null;
  ccCheckIDHasPrefix?: string | null;
  ccCheckIDHasSuffix?: string | null;
  ccCheckIDIn?: ReadonlyArray<string> | null;
  ccCheckIDLT?: string | null;
  ccCheckIDLTE?: string | null;
  ccCheckIDNEQ?: string | null;
  ccCheckIDNotIn?: ReadonlyArray<string> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  dateOfBirth?: any | null;
  dateOfBirthGT?: any | null;
  dateOfBirthGTE?: any | null;
  dateOfBirthIn?: ReadonlyArray<any> | null;
  dateOfBirthIsNil?: boolean | null;
  dateOfBirthLT?: any | null;
  dateOfBirthLTE?: any | null;
  dateOfBirthNEQ?: any | null;
  dateOfBirthNotIn?: ReadonlyArray<any> | null;
  dateOfBirthNotNil?: boolean | null;
  documentNumber?: string | null;
  documentNumberContains?: string | null;
  documentNumberContainsFold?: string | null;
  documentNumberEqualFold?: string | null;
  documentNumberGT?: string | null;
  documentNumberGTE?: string | null;
  documentNumberHasPrefix?: string | null;
  documentNumberHasSuffix?: string | null;
  documentNumberIn?: ReadonlyArray<string> | null;
  documentNumberIsNil?: boolean | null;
  documentNumberLT?: string | null;
  documentNumberLTE?: string | null;
  documentNumberNEQ?: string | null;
  documentNumberNotIn?: ReadonlyArray<string> | null;
  documentNumberNotNil?: boolean | null;
  documentType?: string | null;
  documentTypeContains?: string | null;
  documentTypeContainsFold?: string | null;
  documentTypeEqualFold?: string | null;
  documentTypeGT?: string | null;
  documentTypeGTE?: string | null;
  documentTypeHasPrefix?: string | null;
  documentTypeHasSuffix?: string | null;
  documentTypeIn?: ReadonlyArray<string> | null;
  documentTypeIsNil?: boolean | null;
  documentTypeLT?: string | null;
  documentTypeLTE?: string | null;
  documentTypeNEQ?: string | null;
  documentTypeNotIn?: ReadonlyArray<string> | null;
  documentTypeNotNil?: boolean | null;
  expirationDate?: any | null;
  expirationDateGT?: any | null;
  expirationDateGTE?: any | null;
  expirationDateIn?: ReadonlyArray<any> | null;
  expirationDateIsNil?: boolean | null;
  expirationDateLT?: any | null;
  expirationDateLTE?: any | null;
  expirationDateNEQ?: any | null;
  expirationDateNotIn?: ReadonlyArray<any> | null;
  expirationDateNotNil?: boolean | null;
  firstName?: string | null;
  firstNameContains?: string | null;
  firstNameContainsFold?: string | null;
  firstNameEqualFold?: string | null;
  firstNameGT?: string | null;
  firstNameGTE?: string | null;
  firstNameHasPrefix?: string | null;
  firstNameHasSuffix?: string | null;
  firstNameIn?: ReadonlyArray<string> | null;
  firstNameIsNil?: boolean | null;
  firstNameLT?: string | null;
  firstNameLTE?: string | null;
  firstNameNEQ?: string | null;
  firstNameNotIn?: ReadonlyArray<string> | null;
  firstNameNotNil?: boolean | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  issueAuthority?: string | null;
  issueAuthorityContains?: string | null;
  issueAuthorityContainsFold?: string | null;
  issueAuthorityEqualFold?: string | null;
  issueAuthorityGT?: string | null;
  issueAuthorityGTE?: string | null;
  issueAuthorityHasPrefix?: string | null;
  issueAuthorityHasSuffix?: string | null;
  issueAuthorityIn?: ReadonlyArray<string> | null;
  issueAuthorityIsNil?: boolean | null;
  issueAuthorityLT?: string | null;
  issueAuthorityLTE?: string | null;
  issueAuthorityNEQ?: string | null;
  issueAuthorityNotIn?: ReadonlyArray<string> | null;
  issueAuthorityNotNil?: boolean | null;
  issueCountry?: string | null;
  issueCountryContains?: string | null;
  issueCountryContainsFold?: string | null;
  issueCountryEqualFold?: string | null;
  issueCountryGT?: string | null;
  issueCountryGTE?: string | null;
  issueCountryHasPrefix?: string | null;
  issueCountryHasSuffix?: string | null;
  issueCountryIn?: ReadonlyArray<string> | null;
  issueCountryIsNil?: boolean | null;
  issueCountryLT?: string | null;
  issueCountryLTE?: string | null;
  issueCountryNEQ?: string | null;
  issueCountryNotIn?: ReadonlyArray<string> | null;
  issueCountryNotNil?: boolean | null;
  issueDate?: any | null;
  issueDateGT?: any | null;
  issueDateGTE?: any | null;
  issueDateIn?: ReadonlyArray<any> | null;
  issueDateIsNil?: boolean | null;
  issueDateLT?: any | null;
  issueDateLTE?: any | null;
  issueDateNEQ?: any | null;
  issueDateNotIn?: ReadonlyArray<any> | null;
  issueDateNotNil?: boolean | null;
  kycAttemptID?: string | null;
  kycAttemptIDContains?: string | null;
  kycAttemptIDContainsFold?: string | null;
  kycAttemptIDEqualFold?: string | null;
  kycAttemptIDGT?: string | null;
  kycAttemptIDGTE?: string | null;
  kycAttemptIDHasPrefix?: string | null;
  kycAttemptIDHasSuffix?: string | null;
  kycAttemptIDIn?: ReadonlyArray<string> | null;
  kycAttemptIDLT?: string | null;
  kycAttemptIDLTE?: string | null;
  kycAttemptIDNEQ?: string | null;
  kycAttemptIDNotIn?: ReadonlyArray<string> | null;
  lastName?: string | null;
  lastNameContains?: string | null;
  lastNameContainsFold?: string | null;
  lastNameEqualFold?: string | null;
  lastNameGT?: string | null;
  lastNameGTE?: string | null;
  lastNameHasPrefix?: string | null;
  lastNameHasSuffix?: string | null;
  lastNameIn?: ReadonlyArray<string> | null;
  lastNameIsNil?: boolean | null;
  lastNameLT?: string | null;
  lastNameLTE?: string | null;
  lastNameNEQ?: string | null;
  lastNameNotIn?: ReadonlyArray<string> | null;
  lastNameNotNil?: boolean | null;
  nationality?: string | null;
  nationalityContains?: string | null;
  nationalityContainsFold?: string | null;
  nationalityEqualFold?: string | null;
  nationalityGT?: string | null;
  nationalityGTE?: string | null;
  nationalityHasPrefix?: string | null;
  nationalityHasSuffix?: string | null;
  nationalityIn?: ReadonlyArray<string> | null;
  nationalityIsNil?: boolean | null;
  nationalityLT?: string | null;
  nationalityLTE?: string | null;
  nationalityNEQ?: string | null;
  nationalityNotIn?: ReadonlyArray<string> | null;
  nationalityNotNil?: boolean | null;
  not?: KYCExtractedInfoWhereInput | null;
  or?: ReadonlyArray<KYCExtractedInfoWhereInput> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type AddressWhereInput = {
  addressLineOne?: string | null;
  addressLineOneContains?: string | null;
  addressLineOneContainsFold?: string | null;
  addressLineOneEqualFold?: string | null;
  addressLineOneGT?: string | null;
  addressLineOneGTE?: string | null;
  addressLineOneHasPrefix?: string | null;
  addressLineOneHasSuffix?: string | null;
  addressLineOneIn?: ReadonlyArray<string> | null;
  addressLineOneLT?: string | null;
  addressLineOneLTE?: string | null;
  addressLineOneNEQ?: string | null;
  addressLineOneNotIn?: ReadonlyArray<string> | null;
  addressLineTwo?: string | null;
  addressLineTwoContains?: string | null;
  addressLineTwoContainsFold?: string | null;
  addressLineTwoEqualFold?: string | null;
  addressLineTwoGT?: string | null;
  addressLineTwoGTE?: string | null;
  addressLineTwoHasPrefix?: string | null;
  addressLineTwoHasSuffix?: string | null;
  addressLineTwoIn?: ReadonlyArray<string> | null;
  addressLineTwoIsNil?: boolean | null;
  addressLineTwoLT?: string | null;
  addressLineTwoLTE?: string | null;
  addressLineTwoNEQ?: string | null;
  addressLineTwoNotIn?: ReadonlyArray<string> | null;
  addressLineTwoNotNil?: boolean | null;
  and?: ReadonlyArray<AddressWhereInput> | null;
  ccAddressID?: string | null;
  ccAddressIDContains?: string | null;
  ccAddressIDContainsFold?: string | null;
  ccAddressIDEqualFold?: string | null;
  ccAddressIDGT?: string | null;
  ccAddressIDGTE?: string | null;
  ccAddressIDHasPrefix?: string | null;
  ccAddressIDHasSuffix?: string | null;
  ccAddressIDIn?: ReadonlyArray<string> | null;
  ccAddressIDIsNil?: boolean | null;
  ccAddressIDLT?: string | null;
  ccAddressIDLTE?: string | null;
  ccAddressIDNEQ?: string | null;
  ccAddressIDNotIn?: ReadonlyArray<string> | null;
  ccAddressIDNotNil?: boolean | null;
  city?: string | null;
  cityContains?: string | null;
  cityContainsFold?: string | null;
  cityEqualFold?: string | null;
  cityGT?: string | null;
  cityGTE?: string | null;
  cityHasPrefix?: string | null;
  cityHasSuffix?: string | null;
  cityIn?: ReadonlyArray<string> | null;
  cityLT?: string | null;
  cityLTE?: string | null;
  cityNEQ?: string | null;
  cityNotIn?: ReadonlyArray<string> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  hasKycAttempt?: boolean | null;
  hasKycAttemptWith?: ReadonlyArray<KYCAttemptWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  kycAttemptID?: string | null;
  kycAttemptIDContains?: string | null;
  kycAttemptIDContainsFold?: string | null;
  kycAttemptIDEqualFold?: string | null;
  kycAttemptIDGT?: string | null;
  kycAttemptIDGTE?: string | null;
  kycAttemptIDHasPrefix?: string | null;
  kycAttemptIDHasSuffix?: string | null;
  kycAttemptIDIn?: ReadonlyArray<string> | null;
  kycAttemptIDLT?: string | null;
  kycAttemptIDLTE?: string | null;
  kycAttemptIDNEQ?: string | null;
  kycAttemptIDNotIn?: ReadonlyArray<string> | null;
  not?: AddressWhereInput | null;
  or?: ReadonlyArray<AddressWhereInput> | null;
  postalCode?: string | null;
  postalCodeContains?: string | null;
  postalCodeContainsFold?: string | null;
  postalCodeEqualFold?: string | null;
  postalCodeGT?: string | null;
  postalCodeGTE?: string | null;
  postalCodeHasPrefix?: string | null;
  postalCodeHasSuffix?: string | null;
  postalCodeIn?: ReadonlyArray<string> | null;
  postalCodeLT?: string | null;
  postalCodeLTE?: string | null;
  postalCodeNEQ?: string | null;
  postalCodeNotIn?: ReadonlyArray<string> | null;
  stateProvince?: string | null;
  stateProvinceContains?: string | null;
  stateProvinceContainsFold?: string | null;
  stateProvinceEqualFold?: string | null;
  stateProvinceGT?: string | null;
  stateProvinceGTE?: string | null;
  stateProvinceHasPrefix?: string | null;
  stateProvinceHasSuffix?: string | null;
  stateProvinceIn?: ReadonlyArray<string> | null;
  stateProvinceIsNil?: boolean | null;
  stateProvinceLT?: string | null;
  stateProvinceLTE?: string | null;
  stateProvinceNEQ?: string | null;
  stateProvinceNotIn?: ReadonlyArray<string> | null;
  stateProvinceNotNil?: boolean | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type KYCActionLogWhereInput = {
  action?: KYCActionLogAction | null;
  actionIn?: ReadonlyArray<KYCActionLogAction> | null;
  actionNEQ?: KYCActionLogAction | null;
  actionNotIn?: ReadonlyArray<KYCActionLogAction> | null;
  and?: ReadonlyArray<KYCActionLogWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  currentAttemptState?: KYCActionLogCurrentAttemptState | null;
  currentAttemptStateIn?: ReadonlyArray<KYCActionLogCurrentAttemptState> | null;
  currentAttemptStateIsNil?: boolean | null;
  currentAttemptStateNEQ?: KYCActionLogCurrentAttemptState | null;
  currentAttemptStateNotIn?: ReadonlyArray<KYCActionLogCurrentAttemptState> | null;
  currentAttemptStateNotNil?: boolean | null;
  currentReviewState?: KYCActionLogCurrentReviewState | null;
  currentReviewStateIn?: ReadonlyArray<KYCActionLogCurrentReviewState> | null;
  currentReviewStateIsNil?: boolean | null;
  currentReviewStateNEQ?: KYCActionLogCurrentReviewState | null;
  currentReviewStateNotIn?: ReadonlyArray<KYCActionLogCurrentReviewState> | null;
  currentReviewStateNotNil?: boolean | null;
  data?: string | null;
  dataContains?: string | null;
  dataContainsFold?: string | null;
  dataEqualFold?: string | null;
  dataGT?: string | null;
  dataGTE?: string | null;
  dataHasPrefix?: string | null;
  dataHasSuffix?: string | null;
  dataIn?: ReadonlyArray<string> | null;
  dataIsNil?: boolean | null;
  dataLT?: string | null;
  dataLTE?: string | null;
  dataNEQ?: string | null;
  dataNotIn?: ReadonlyArray<string> | null;
  dataNotNil?: boolean | null;
  hasKycAttempt?: boolean | null;
  hasKycAttemptWith?: ReadonlyArray<KYCAttemptWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  kycAttemptID?: string | null;
  kycAttemptIDContains?: string | null;
  kycAttemptIDContainsFold?: string | null;
  kycAttemptIDEqualFold?: string | null;
  kycAttemptIDGT?: string | null;
  kycAttemptIDGTE?: string | null;
  kycAttemptIDHasPrefix?: string | null;
  kycAttemptIDHasSuffix?: string | null;
  kycAttemptIDIn?: ReadonlyArray<string> | null;
  kycAttemptIDLT?: string | null;
  kycAttemptIDLTE?: string | null;
  kycAttemptIDNEQ?: string | null;
  kycAttemptIDNotIn?: ReadonlyArray<string> | null;
  message?: string | null;
  messageContains?: string | null;
  messageContainsFold?: string | null;
  messageEqualFold?: string | null;
  messageGT?: string | null;
  messageGTE?: string | null;
  messageHasPrefix?: string | null;
  messageHasSuffix?: string | null;
  messageIn?: ReadonlyArray<string> | null;
  messageIsNil?: boolean | null;
  messageLT?: string | null;
  messageLTE?: string | null;
  messageNEQ?: string | null;
  messageNotIn?: ReadonlyArray<string> | null;
  messageNotNil?: boolean | null;
  not?: KYCActionLogWhereInput | null;
  or?: ReadonlyArray<KYCActionLogWhereInput> | null;
  prevAttemptState?: KYCActionLogPrevAttemptState | null;
  prevAttemptStateIn?: ReadonlyArray<KYCActionLogPrevAttemptState> | null;
  prevAttemptStateIsNil?: boolean | null;
  prevAttemptStateNEQ?: KYCActionLogPrevAttemptState | null;
  prevAttemptStateNotIn?: ReadonlyArray<KYCActionLogPrevAttemptState> | null;
  prevAttemptStateNotNil?: boolean | null;
  prevReviewState?: KYCActionLogPrevReviewState | null;
  prevReviewStateIn?: ReadonlyArray<KYCActionLogPrevReviewState> | null;
  prevReviewStateIsNil?: boolean | null;
  prevReviewStateNEQ?: KYCActionLogPrevReviewState | null;
  prevReviewStateNotIn?: ReadonlyArray<KYCActionLogPrevReviewState> | null;
  prevReviewStateNotNil?: boolean | null;
  subjectEmail?: string | null;
  subjectEmailContains?: string | null;
  subjectEmailContainsFold?: string | null;
  subjectEmailEqualFold?: string | null;
  subjectEmailGT?: string | null;
  subjectEmailGTE?: string | null;
  subjectEmailHasPrefix?: string | null;
  subjectEmailHasSuffix?: string | null;
  subjectEmailIn?: ReadonlyArray<string> | null;
  subjectEmailIsNil?: boolean | null;
  subjectEmailLT?: string | null;
  subjectEmailLTE?: string | null;
  subjectEmailNEQ?: string | null;
  subjectEmailNotIn?: ReadonlyArray<string> | null;
  subjectEmailNotNil?: boolean | null;
  subjectType?: KYCActionLogSubjectType | null;
  subjectTypeIn?: ReadonlyArray<KYCActionLogSubjectType> | null;
  subjectTypeNEQ?: KYCActionLogSubjectType | null;
  subjectTypeNotIn?: ReadonlyArray<KYCActionLogSubjectType> | null;
  subjectUserID?: string | null;
  subjectUserIDContains?: string | null;
  subjectUserIDContainsFold?: string | null;
  subjectUserIDEqualFold?: string | null;
  subjectUserIDGT?: string | null;
  subjectUserIDGTE?: string | null;
  subjectUserIDHasPrefix?: string | null;
  subjectUserIDHasSuffix?: string | null;
  subjectUserIDIn?: ReadonlyArray<string> | null;
  subjectUserIDLT?: string | null;
  subjectUserIDLTE?: string | null;
  subjectUserIDNEQ?: string | null;
  subjectUserIDNotIn?: ReadonlyArray<string> | null;
  targetUserID?: string | null;
  targetUserIDContains?: string | null;
  targetUserIDContainsFold?: string | null;
  targetUserIDEqualFold?: string | null;
  targetUserIDGT?: string | null;
  targetUserIDGTE?: string | null;
  targetUserIDHasPrefix?: string | null;
  targetUserIDHasSuffix?: string | null;
  targetUserIDIn?: ReadonlyArray<string> | null;
  targetUserIDLT?: string | null;
  targetUserIDLTE?: string | null;
  targetUserIDNEQ?: string | null;
  targetUserIDNotIn?: ReadonlyArray<string> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type KYCRiskScoreWhereInput = {
  and?: ReadonlyArray<KYCRiskScoreWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  hasKycAttempt?: boolean | null;
  hasKycAttemptWith?: ReadonlyArray<KYCAttemptWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  kycAttemptID?: string | null;
  kycAttemptIDContains?: string | null;
  kycAttemptIDContainsFold?: string | null;
  kycAttemptIDEqualFold?: string | null;
  kycAttemptIDGT?: string | null;
  kycAttemptIDGTE?: string | null;
  kycAttemptIDHasPrefix?: string | null;
  kycAttemptIDHasSuffix?: string | null;
  kycAttemptIDIn?: ReadonlyArray<string> | null;
  kycAttemptIDLT?: string | null;
  kycAttemptIDLTE?: string | null;
  kycAttemptIDNEQ?: string | null;
  kycAttemptIDNotIn?: ReadonlyArray<string> | null;
  level?: KYCRiskScoreLevel | null;
  levelIn?: ReadonlyArray<KYCRiskScoreLevel> | null;
  levelNEQ?: KYCRiskScoreLevel | null;
  levelNotIn?: ReadonlyArray<KYCRiskScoreLevel> | null;
  not?: KYCRiskScoreWhereInput | null;
  or?: ReadonlyArray<KYCRiskScoreWhereInput> | null;
  score?: number | null;
  scoreGT?: number | null;
  scoreGTE?: number | null;
  scoreIn?: ReadonlyArray<number> | null;
  scoreLT?: number | null;
  scoreLTE?: number | null;
  scoreNEQ?: number | null;
  scoreNotIn?: ReadonlyArray<number> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
  version?: KYCRiskScoreVersion | null;
  versionIn?: ReadonlyArray<KYCRiskScoreVersion> | null;
  versionNEQ?: KYCRiskScoreVersion | null;
  versionNotIn?: ReadonlyArray<KYCRiskScoreVersion> | null;
};
export type UserIdentityOrder = {
  direction: OrderDirection;
  field: UserIdentityOrderField;
};
export type UserIdentitiesQuery$variables = {
  after?: any | null;
  before?: any | null;
  first?: number | null;
  last?: number | null;
  orderBy?: UserIdentityOrder | null;
  where?: UserIdentityWhereInput | null;
};
export type UserIdentitiesQuery$data = {
  readonly userIdentities: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accountID: string;
        readonly createTime: any | null;
        readonly currentKyc: {
          readonly kycTags: ReadonlyArray<{
            readonly id: string;
            readonly value: KYCTagValue;
          }> | null;
          readonly state: KYCAttemptState;
        } | null;
        readonly email: string | null;
        readonly firstName: string | null;
        readonly id: string;
        readonly kycReviewState: UserIdentityKycReviewState;
        readonly lastName: string | null;
        readonly updateTime: any | null;
        readonly userID: string;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: any | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: any | null;
    };
    readonly totalCount: number;
  };
};
export type UserIdentitiesQuery = {
  response: UserIdentitiesQuery$data;
  variables: UserIdentitiesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createTime",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updateTime",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userID",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountID",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kycReviewState",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "KYCTag",
  "kind": "LinkedField",
  "name": "kycTags",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserIdentitiesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "UserIdentityConnection",
        "kind": "LinkedField",
        "name": "userIdentities",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserIdentityEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserIdentity",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "KYCAttempt",
                    "kind": "LinkedField",
                    "name": "currentKyc",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserIdentitiesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "UserIdentityConnection",
        "kind": "LinkedField",
        "name": "userIdentities",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserIdentityEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserIdentity",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "KYCAttempt",
                    "kind": "LinkedField",
                    "name": "currentKyc",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b1c0baef10f832f8469b771c05945a81",
    "id": null,
    "metadata": {},
    "name": "UserIdentitiesQuery",
    "operationKind": "query",
    "text": "query UserIdentitiesQuery(\n  $after: Cursor\n  $first: Int\n  $before: Cursor\n  $last: Int\n  $where: UserIdentityWhereInput\n  $orderBy: UserIdentityOrder\n) {\n  userIdentities(after: $after, first: $first, before: $before, last: $last, where: $where, orderBy: $orderBy) {\n    totalCount\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        id\n        createTime\n        updateTime\n        userID\n        accountID\n        email\n        firstName\n        lastName\n        kycReviewState\n        currentKyc {\n          state\n          kycTags {\n            id\n            value\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4e12769197112cdc12592300d9caca32";

export default node;
