import { Button, Space } from 'antd'
import { Link } from 'react-router-dom'
import { TUserPiiOverview } from 'src/graphql'
import { generate } from 'src/router'
import { ClipboardService, TzService } from 'src/services'
import { KycUtils } from 'src/utils'
import { EPaths } from '../../routes.path'

interface Actions {
  accountType: 'business' | 'individual'
  onGoDetail?: () => void
}

export const renderColumns = ({ accountType, onGoDetail }: Actions) => [
  {
    title: 'User name',
    dataIndex: 'userName',
    key: 'userName',
    className: 'break-all min-w-150',
    render: (_: any, record: TUserPiiOverview) => [record.firstName, record.lastName].filter(Boolean).join(' ')
  },
  {
    title: 'Account ID',
    dataIndex: 'accountID',
    key: 'accountID',
    className: 'break-all min-w-200',
    render: (accountID: string) => (
      <div
        className="pointer"
        onClick={() => ClipboardService.copy(accountID)}
      >
        {accountID}
      </div>
    )
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    className: 'break-all min-w-200'
  },
  {
    title: 'KYC status',
    dataIndex: 'kycStatus',
    key: 'KYC_REVIEW_STATE',
    render: (_: any, record: TUserPiiOverview) => KycUtils.renderKycStatusBadge(record.kycReviewState),
    sorter: true
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    key: 'tags',
    render: (_: any, record: TUserPiiOverview) => (
      <Space size={[0, 8]} wrap>
        {KycUtils.renderTags(record.currentKyc?.kycTags)}
      </Space>
    )
  },
  {
    title: 'Create time',
    dataIndex: 'createTime',
    key: 'CREATE_TIME',
    className: 'break-all min-w-200',
    render: (_: any, record: TUserPiiOverview) => record.createTime
      ? TzService.format(record.createTime, 'HH:mm YYYY-MM-DD')
      : '--',
    sorter: true
  },
  {
    title: 'Last update time',
    dataIndex: 'updateTime',
    key: 'UPDATE_TIME',
    className: 'break-all min-w-200',
    render: (_: any, record: TUserPiiOverview) => record.updateTime
      ? TzService.format(record.updateTime, 'HH:mm YYYY-MM-DD')
      : '--',
    sorter: true
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fixed: 'right' as any,
    render: (_: any, record: TUserPiiOverview) => (
      <Space>
        <Button
          size="small"
          type="link"
        >
          <Link
            to={generate([accountType === 'business' ? EPaths.MARKET_BUSINESS_KYC_DETAIL : EPaths.MARKET_INDIVIDUAL_KYC_DETAIL, {
              accountID: record.accountID,
              userID: record.userID
            }])}
            onClick={onGoDetail}
          >
            Detail
          </Link>
        </Button>
      </Space>
    )
  }
]
