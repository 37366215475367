import { Collapse, CollapseProps, Space } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { PiiApi } from 'src/api'
import { TBusinessUserPii } from 'src/graphql'
import { useUnsubscribe } from 'src/hooks'
import { EPaths } from 'src/modules/market/routes.path'
import { DialogEditBusinessProfile, DialogKybActions, DialogKybComment, DialogKybDocument } from 'src/partials'
import { BusinessKybApplicantInfo, BusinessKybChangelog, BusinessKybContactInfo, BusinessKybDocument, BusinessKybProfile, BusinessKybUserList } from 'src/partials/business-kyb'
import { BreadcrumbService } from 'src/services'
import { EventUtils, KybUtils } from 'src/utils'

export const BusinessKybDetail: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { accountID } = useParams<{
    accountID: string
  }>()
  const [user, setUser] = useState<TBusinessUserPii>()

  /**
   * breadcrumb
   */
  useEffect(() => {
    // success
    // processing
    // error
    // default
    // warning
    const kybState = String(user?.finalState)
    BreadcrumbService.items = [
      {
        route: EPaths.MARKET_BUSINESS_KYB,
        label: 'Business KYB'
      },
      {
        route: [EPaths.MARKET_BUSINESS_KYB_DETAIL, { accountID }],
        label: user?.id
          ? (
            <Space>
              {user?.email}
              {!!kybState && KybUtils.renderKybStatusBadge(kybState)}
            </Space>
          )
          : accountID
      }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [accountID, user?.email, user?.finalState, user?.id])

  const pullUserDetails = useCallback((input: Parameters<typeof PiiApi.businessAccountIdentity>[0]) => {
    from(PiiApi.businessAccountIdentity({ accountID: input.accountID }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          console.log('error', error)
          return EMPTY
        })
      )
      .subscribe((userIdentity) => {
        setUser(userIdentity as TBusinessUserPii)
      })
  }, [unsubscribe$])

  const onUpdated = useCallback(() => {
    return user?.accountID && pullUserDetails({
      accountID: user.accountID
    })
  }, [pullUserDetails, user?.accountID])

  useEffect(() => {
    if (!accountID) {
      return
    }
    pullUserDetails({ accountID })
  }, [accountID, pullUserDetails])

  const items: CollapseProps['items'] = useMemo(() => [
    {
      key: '1',
      label: 'Applicant Info',
      children: <BusinessKybApplicantInfo user={user} onUpdated={onUpdated}/>
    },
    {
      key: '2',
      label: 'Business Profile',
      children: <BusinessKybProfile user={user} onUpdated={onUpdated}/>,
      extra: (
        <div onClick={e => EventUtils.stopPropagation(e)}>
          {user && (
            <DialogEditBusinessProfile
              user={user}
              btnProps={{
                type: 'link',
                style: { margin: '-8px 0' }
              }}
              afterClose={(updated) => updated && onUpdated()}
            />
          )}
        </div>
      )
    },
    {
      key: '3',
      label: 'Contact Info',
      children: <BusinessKybContactInfo user={user}/>
    },
    {
      key: '4',
      label: 'User List',
      children: <BusinessKybUserList user={user}/>
    },
    {
      key: '5',
      label: 'Document',
      children: <BusinessKybDocument user={user}/>,
      extra: (
        <div onClick={e => EventUtils.stopPropagation(e)}>
          {user?.currentKyb && (
            <DialogKybDocument
              user={user}
              btnProps={{
                type: 'link',
                style: { margin: '-8px 0' }
              }}
              afterClose={(updated) => updated && onUpdated()}
            />
          )}
        </div>
      )
    },
    {
      key: '6',
      label: 'Change log',
      children: <BusinessKybChangelog user={user}/>,
      extra: (
        <div onClick={e => EventUtils.stopPropagation(e)}>
          {user && (
            <DialogKybComment
              user={user}
              btnProps={{
                type: 'link',
                style: { margin: '-8px 0' }
              }}
              afterClose={(updated) => updated && onUpdated()}
            />
          )}
        </div>
      )
    }
  ], [onUpdated, user])

  return (
    <section className="fx fx-column fx-extend gap-3">
      {user && (
        <Space className="fx-jc-flex-end">
          {KybUtils.canOnHold(user) && (
            <DialogKybActions
              user={user}
              action="on_hold"
              title="On Hold"
              btnProps={{ type: 'primary' }}
              afterClose={(updated) => updated && onUpdated()}
            >
              Onhold
            </DialogKybActions>
          )}
          {KybUtils.canStartReview(user) && (
            <DialogKybActions
              user={user}
              action="start_review"
              title="Start Review"
              btnProps={{ type: 'primary' }}
              afterClose={(updated) => updated && onUpdated()}
            >
              Start Review
            </DialogKybActions>
          )}
          {KybUtils.canApprove(user) && (
            <DialogKybActions
              user={user}
              action="approve"
              title="Approve"
              btnProps={{ type: 'primary' }}
              afterClose={(updated) => updated && onUpdated()}
            >
              Approve
            </DialogKybActions>
          )}
          {KybUtils.canDeny(user) && (
            <DialogKybActions
              user={user}
              action="reject"
              title="Deny"
              btnProps={{ type: 'primary', danger: true }}
              afterClose={(updated) => updated && onUpdated()}
            >
              Deny
            </DialogKybActions>
          )}
        </Space>
      )}

      <Collapse
        items={items}
        defaultActiveKey={['1']}
      />
    </section>
  )
}
